import clsx from 'clsx';
import {CustomMenuItem} from '../../menus/custom-menu';
import {LocaleSwitcher} from '../../i18n/locale-switcher';
import {DarkModeIcon} from '../../icons/material/DarkMode';
import {LightbulbIcon} from '../../icons/material/Lightbulb';
import {Trans} from '../../i18n/trans';
import {useThemeSelector} from '../themes/theme-selector-context';
import {useSettings} from '../../core/settings/use-settings';
import apple from '../../../../../public/assets/images/footer/apple.svg';
import playstore from '../../../../../public/assets/images/footer/google-play.svg';

//react-bootstrap
import {Col} from 'react-bootstrap';

//react-router-dom
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Logo} from '../navigation/navbar/logo';
import {Button as MainButton} from '../buttons/button';
import {useSelectedLocale} from '@common/i18n/selected-locale';
import {useAuth} from '@common/auth/use-auth';
import {createSvgIconFromTree} from '@common/icons/create-svg-icon';
import { useState } from 'react';

interface Props {
  className?: string;
  padding?: string;
}

export function Footer({className, padding}: Props) {
  const year = new Date().getFullYear();
  const {branding} = useSettings();
  const {t} = useTranslation();
  const {selectedTheme, selectTheme} = useThemeSelector();
  const {locale} = useSelectedLocale();
  const settings = useSettings();

  const [newsLetterInput, setNewsLetterInput] = useState<string>("")

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const makeIcon = (iconData: any) => {
    const Icon = createSvgIconFromTree(iconData);
    return <Icon className="h-18 w-18" />;
  };

  return (
    <footer>
      <div className="flex w-screen flex-col gap-24 overflow-hidden bg-[#111] px-12 pb-24 pt-24 lg:gap-40 lg:px-80 lg:pb-16 lg:pt-60">
        <Logo
          logoColor={!selectedTheme.is_dark ? 'dark' : 'light'}
          isDarkMode={selectedTheme.is_dark}
          pictureClassName="flex items-center justify-center lg:justify-start"
        />
        <div className="flex w-full flex-col justify-between lg:flex-row">
          {' '}
          <Col
            xl={3}
            lg={6}
            className="mb-lg-0 mb-5 flex w-full flex-col items-center justify-center lg:w-fit lg:items-start lg:justify-start"
          >
            {/* <h4
              className={clsx(
                'footer-link-title',
                selectedTheme.is_dark ? 'text-white' : 'text-black',
              )}
            >
              <Trans message="Follow Us" />
            </h4> */}
            <ul className="widget_social_media m-0 gap-3 p-0">
              {settings.menus
                .find(m => m.positions?.includes('footer-secondary'))
                ?.items.map(item => (
                  <li key={item.id} className="">
                    <Link
                      to={item.action}
                      target={item.target}
                      className="flex items-center justify-center"
                    >
                      {makeIcon(item.icon)}
                    </Link>
                  </li>
                ))}
            </ul>
          </Col>
          <ul className="flex flex-col !p-0">
            <p className="mb-24 text-base font-semibold text-white">
              <Trans message="Quick links" />
            </p>
            <Menus />
          </ul>
          <div className="line-bg mb-24 h-1 w-full lg:hidden"></div>
          <Col xl={3} lg={6}>
            <div className="font-size-18 font-bold">
              <Trans message="News Letter" />
            </div>
            <div className="mailchimp mailchimp-dark">
              <div className="input-group mb-3 mt-4">
                <input
                  type="email"
                  value={newsLetterInput}
                  className={clsx(
                    'form-control font-size-14 mb-0',
                    locale.language === 'fa'
                      ? '!rounded-l-none'
                      : '!rounded-r-none',
                    selectedTheme.is_dark
                      ? 'bg-black text-white'
                      : 'bg-white text-black',
                  )}
                  placeholder={t('footer.email')}
                  aria-describedby="button-addon2"
                  style={{border: '1px solid red'}}
                  onChange={(e) => setNewsLetterInput(e.target.value)}
                />
                <div className="iq-button">
                  <button
                    type="submit"
                    className="btn btn-sm"
                    id="button-addon2"
                  >
                    <Trans message="Subscribe" />
                  </button>
                </div>
              </div>
            </div>{' '}
            <div className="grid grid-cols-2 gap-2">
              <Link
                className="app-image flex h-54 items-center justify-center rounded bg-black px-14 py-2"
                to="#"
                title="coming soon"
              >
                <img
                  width={175}
                  src={playstore}
                  loading="lazy"
                  alt="play-store"
                />
              </Link>

              <Link
                className="app-image flex h-54 items-center justify-center rounded bg-black px-14 py-2"
                to="#"
                title="coming soon"
              >
                <img width={150} src={apple} loading="lazy" alt="app-store" />
              </Link>
            </div>
            <div className="mt-2 flex gap-2">
              <LocaleSwitcher />
              <ThemeSwitcher />
            </div>
          </Col>
        </div>
        <div className="line-bg h-1 w-full"></div>{' '}
        <div className="-mt-8 flex w-full items-center justify-center lg:-mt-18">
          <Trans
            message="Copyright © :year :name, All Rights Reserved"
            values={{year, name: branding.site_name}}
          />
        </div>
      </div>
    </footer>
  );
}

function Menus() {
  const settings = useSettings();
  const primaryMenu = settings.menus.find(m => m.positions?.includes('footer'));
  const {isLoggedIn} = useAuth();

  // const secondaryMenu = settings.menus.find(m =>
  //   m.positions?.includes('footer-secondary'),
  // );

  if (!primaryMenu) return null;

  return (
    <div className="!m-0 flex flex-col gap-16 !p-0 ">
      {primaryMenu?.items.map(
        item =>
          (item.action !== '/contact' || isLoggedIn) && (
            <CustomMenuItem isFooter item={item} key={item.id} />
          ),
      )}
    </div>
  );
}

function ThemeSwitcher() {
  const {themes} = useSettings();
  const {selectedTheme, selectTheme} = useThemeSelector();
  if (!selectedTheme || !themes?.user_change) return null;

  return (
    <MainButton
      className="max-w-100"
      variant="text"
      startIcon={selectedTheme.is_dark ? <LightbulbIcon /> : <DarkModeIcon />}
      onClick={() => {
        if (selectedTheme.is_dark) {
          selectTheme('light');
        } else {
          selectTheme('dark');
        }
      }}
    >
      {selectedTheme.is_dark ? (
        <Trans message="Light mode" />
      ) : (
        <Trans message="Dark mode" />
      )}
    </MainButton>
  );
}

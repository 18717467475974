import React, {ReactNode} from 'react';
import clsx from 'clsx';
import {DialogSize} from './dialog';
import {useSelectedLocale} from '@common/i18n/selected-locale';

interface DialogFooterProps {
  children: ReactNode;
  startAction?: ReactNode;
  className?: string;
  dividerTop?: boolean;
  size?: DialogSize;
  padding?: string;
}
export function DialogFooter(props: DialogFooterProps) {
  const {children, startAction, className, dividerTop, padding, size} = props;
  const {locale} = useSelectedLocale();

  return (
    <div
      className={clsx(
        className,
        dividerTop && 'border-t',
        getPadding(props),
        'flex flex-shrink-0 items-center gap-10',
      )}
    >
      <div>{startAction}</div>
      <div
        className={clsx(
          'flex items-center gap-10',
          locale.language === 'fa' ? 'mr-auto' : 'ml-auto',
        )}
      >
        {children}
      </div>
    </div>
  );
}

function getPadding({padding, size}: DialogFooterProps) {
  if (padding) {
    return padding;
  }
  switch (size) {
    case 'xs':
      return 'p-14';
    case 'sm':
      return 'p-18';
    default:
      return 'px-24 py-20';
  }
}

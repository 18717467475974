import {Link, useSearchParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {FormTextField} from '../../ui/forms/input-field/text-field/text-field';
import {Button} from '../../ui/buttons/button';
import {Form} from '../../ui/forms/form';
import {LinkStyle} from '../../ui/buttons/external-link';
import {AuthLayout} from './auth-layout/auth-layout';
import {
  SendPasswordResetEmailPayload,
  useSendPasswordResetEmail,
} from '../requests/send-reset-password-email';
import {Trans} from '../../i18n/trans';
import {StaticPageTitle} from '../../seo/static-page-title';
import {useSettings} from '../../core/settings/use-settings';
import {Col, Container, Row} from 'react-bootstrap';
import {useTrans} from '@common/i18n/use-trans';

export function ForgotPasswordPage() {
  const {registration} = useSettings();

  const [searchParams] = useSearchParams();
  const searchParamsEmail = searchParams.get('email') || undefined;

  const form = useForm<SendPasswordResetEmailPayload>({
    defaultValues: {email: searchParamsEmail},
  });
  const sendEmail = useSendPasswordResetEmail(form);

  const message = !registration.disable && (
    <Trans
      values={{
        a: parts => (
          <Link className={LinkStyle} to="/register">
            {parts}
          </Link>
        ),
      }}
      message="Don't have an account? <a>Sign up.</a>"
    />
  );

  const {branding} = useSettings();
  const {trans} = useTrans();

  return (
    <>
      <main className="main-content">
        <div
          className="vh-100"
          style={{
            backgroundImage:
              'url(https://static.vecteezy.com/system/resources/previews/005/585/796/original/abstract-dark-purple-background-overlap-layer-on-dark-space-for-background-design-exclusive-wallpaper-design-for-poster-brochure-presentation-website-etc-vector.jpg)',
            backgroundSize: 'contain',
            backgroundRepeat: 'repeat',
            position: 'relative',
            minHeight: '500px',
          }}
        >
          <Container>
            <Row className="justify-content-center align-items-center height-self-center vh-100">
              <Col lg="5" md="12" className="align-self-center">
                <div className="user-login-card bg-body">
                  <div className="text-center">
                    <Link
                      to="/"
                      className="mb-40 block flex-shrink-0"
                      aria-label={trans({message: 'Go to homepage'})}
                    >
                      <img
                        src={branding.logo_light}
                        className="m-auto block h-42 w-auto"
                        alt=""
                      />
                    </Link>
                  </div>
                  <StaticPageTitle>
                    <Trans message="Forgot Password" />
                  </StaticPageTitle>
                  <Form
                    form={form}
                    onSubmit={payload => {
                      sendEmail.mutate(payload);
                    }}
                  >
                    <div className="mb-32 text-sm">
                      <Trans message="Enter your email address below and we will send you a link to reset or create your password." />
                    </div>
                    <FormTextField
                      disabled={!!searchParamsEmail}
                      className="mb-32"
                      name="email"
                      type="email"
                      autoComplete="off"
                      autoCorrect="off"
                      spellCheck="false"
                      label={<Trans message="Email" />}
                      required
                    />
                    <Button
                      className="block w-full"
                      type="submit"
                      variant="flat"
                      color="primary"
                      size="md"
                      disabled={sendEmail.isPending}
                    >
                      <Trans message="Continue" />
                    </Button>
                  </Form>
                  <div className="fw-500 my-4 flex justify-center gap-2 text-center text-white">
                    <p>
                      <Trans message="Don't have account?" />
                    </p>
                    <Link to="/register">
                      <Trans message="register" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </main>
    </>
    // <AuthLayout message={message}>
    //   <StaticPageTitle>
    //     <Trans message="Forgot Password" />
    //   </StaticPageTitle>

    // </AuthLayout>
  );
}

import {Link, Navigate, useLocation, useSearchParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {FormTextField} from '../../ui/forms/input-field/text-field/text-field';
import {Button} from '../../ui/buttons/button';
import {Form} from '../../ui/forms/form';
import {LinkStyle} from '../../ui/buttons/external-link';
import {RegisterPayload, useRegister} from '../requests/use-register';
import {SocialAuthSection} from './social-auth-section';
import {AuthLayout} from './auth-layout/auth-layout';
import {Trans} from '../../i18n/trans';
import {FormCheckbox} from '../../ui/forms/toggle/checkbox';
import {CustomMenuItem} from '../../menus/custom-menu';
import {useRecaptcha} from '../../recaptcha/use-recaptcha';
import {StaticPageTitle} from '../../seo/static-page-title';
import {useSettings} from '../../core/settings/use-settings';
import {useContext} from 'react';
import {SiteConfigContext} from '@common/core/settings/site-config-context';
import {Col, Container, Row} from 'react-bootstrap';
import {useTrans} from '@common/i18n/use-trans';
import {SocialService, useSocialLogin} from '../requests/use-social-login';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {useAuth} from '../use-auth';

export function RegisterPage() {
  const {
    branding,
    registration: {disable},
    social,
  } = useSettings();
  const {trans} = useTrans();
  const {auth} = useContext(SiteConfigContext);
  const {verify, isVerifying} = useRecaptcha('register');

  const {pathname} = useLocation();
  const [searchParams] = useSearchParams();

  const isWorkspaceRegister = pathname.includes('workspace');
  const isBillingRegister = searchParams.get('redirectFrom') === 'pricing';
  const searchParamsEmail = searchParams.get('email') || undefined;

  const form = useForm<RegisterPayload>({
    defaultValues: {email: searchParamsEmail},
  });
  const register = useRegister(form);

  if (disable) {
    return <Navigate to="/login" replace />;
  }

  let heading = <Trans message="Create a new account" />;
  if (isWorkspaceRegister) {
    heading = (
      <Trans
        values={{siteName: branding?.site_name}}
        message="To join your team on :siteName, create an account"
      />
    );
  } else if (isBillingRegister) {
    heading = <Trans message="First, let's create your account" />;
  }

  return (
    <>
      <main className="main-content">
        <div
          style={{
            backgroundImage:
              'url(https://static.vecteezy.com/system/resources/previews/005/585/796/original/abstract-dark-purple-background-overlap-layer-on-dark-space-for-background-design-exclusive-wallpaper-design-for-poster-brochure-presentation-website-etc-vector.jpg)',
            backgroundSize: 'contain',
            backgroundRepeat: 'repeat',
            position: 'relative',
            minHeight: '500px',
          }}
        >
          <Container>
            <Row className="justify-content-center align-items-center height-self-center vh-100">
              <Col lg="5" md="12" className="align-self-center">
                <div className="user-login-card bg-body">
                  <div className="text-center">
                    <Link
                      to="/"
                      className="mb-40 block flex-shrink-0"
                      aria-label={trans({message: 'Go to homepage'})}
                    >
                      <img
                        src={branding.logo_light}
                        className="m-auto block h-42 w-auto"
                        alt=""
                      />
                    </Link>
                  </div>
                  <StaticPageTitle>
                    <Trans message="Register" />
                  </StaticPageTitle>
                  <Form
                    form={form}
                    onSubmit={async payload => {
                      const isValid = await verify();
                      if (isValid) {
                        register.mutate(payload);
                      }
                    }}
                  >
                    <FormTextField
                      className="mb-16"
                      name="email"
                      type="email"
                      disabled={!!searchParamsEmail}
                      label={<Trans message="Email" />}
                      required
                    />
                    <FormTextField
                      className="mb-16"
                      name="password"
                      type="password"
                      label={<Trans message="Password" />}
                      required
                    />
                    <FormTextField
                      className="mb-16"
                      name="password_confirmation"
                      type="password"
                      label={<Trans message="Confirm password" />}
                      required
                    />
                    {auth?.registerFields ? <auth.registerFields /> : null}
                    <PolicyCheckboxes />
                    <Button
                      className="block w-full"
                      type="submit"
                      variant="flat"
                      color="primary"
                      size="md"
                      disabled={register.isPending || isVerifying}
                    >
                      <Trans message="Create account" />
                    </Button>
                  </Form>
                  <div className="fw-500 my-4 flex justify-center gap-2 text-center text-white">
                    <p>
                      <Trans message="Already have an account?" />
                    </p>
                    <Link to="/login">
                      <Trans message="login" />
                    </Link>
                  </div>
                  <div className="seperator d-flex justify-content-center align-items-center">
                    <span className="line"></span>
                    <span className="mx-2">
                      {' '}
                      <Trans message="OR" />
                    </span>
                    <span className="line"></span>
                  </div>
                  <SocialAuthSection />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </main>
    </>
  );
}

function PolicyCheckboxes() {
  const {
    registration: {policies},
  } = useSettings();

  if (!policies) return null;

  return (
    <div className="mb-32">
      {policies.map(policy => (
        <FormCheckbox
          key={policy.id}
          name={policy.id}
          className="mb-4 block"
          required
        >
          <Trans
            message="I accept the :name"
            values={{
              name: (
                <CustomMenuItem
                  unstyled
                  className={() => LinkStyle}
                  item={policy}
                />
              ),
            }}
          />
        </FormCheckbox>
      ))}
    </div>
  );
}

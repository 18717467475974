/* eslint-disable react/no-unescaped-entities */
import {CustomPage} from '@common/admin/custom-pages/custom-page';
import {useEffect, useRef} from 'react';
import {highlightAllCode} from '@common/text-editor/highlight/highlight-code';
import {Col, Container, Row, Breadcrumb} from 'react-bootstrap';
import { useThemeSelector } from '@common/ui/themes/theme-selector-context';

interface CustomPageBodyProps {
  page: CustomPage;
}
export function CustomPageBody({page}: CustomPageBodyProps) {
  const bodyRef = useRef<HTMLDivElement>(null);
  const {selectedTheme} = useThemeSelector();

  useEffect(() => {
    if (bodyRef.current) {
      highlightAllCode(bodyRef.current);
    }
  }, []);

  return (
    <>
      <div
        className="iq-breadcrumb"
        style={{
          backgroundImage: `url(https://policycloud.eu/sites/default/files/slider_bg.jpg)`,
        }}
      >
        <Container  fluid>
          <Row className="align-items-center">
            <Col sm="12">
              <nav className="text-center">
                <h2 className={`title capitalize`}>{page.title}</h2>
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        ref={bodyRef}
        className={`custom-box mx-124 py-48 [@media(max-width:767px)]:mx-12 [@media(max-width:767px)]:py-28 ${!selectedTheme.is_dark ? "text-black bg-white" : "text-white bg-black"}`}
        dangerouslySetInnerHTML={{__html: page.body}}
      />
    </>
  );
}

import {ProgressCircle} from './progress-circle';
import React from 'react';
import clsx from 'clsx';
import loaderGif from '../../../../../public/assets/images/loader.gif';

interface FullPageLoaderProps {
  className?: string;
  screen?: boolean;
}
export function FullPageLoader({className, screen}: FullPageLoaderProps) {
  return (
    <div
      className={clsx(
        'flex flex-auto items-center justify-center',
        screen ? 'h-screen w-screen' : 'h-full w-full',
        className,
      )}
    >
      <img
        src={loaderGif}
        alt="loader"
        className="img-fluid"
        width="300"
      />
      {/* <ProgressCircle isIndeterminate aria-label="Loading page..." /> */}
    </div>
  );
}

import {Link, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {FormTextField} from '../../ui/forms/input-field/text-field/text-field';
import {Button} from '../../ui/buttons/button';
import {Form} from '../../ui/forms/form';
import {LinkStyle} from '../../ui/buttons/external-link';
import {
  ResetPasswordPayload,
  useResetPassword,
} from '../requests/reset-password';
import {Trans} from '../../i18n/trans';
import {Col, Container, Row} from 'react-bootstrap';
import {useSettings} from '@common/core/settings/use-settings';
import {useTrans} from '@common/i18n/use-trans';

export function ResetPasswordPage() {
  const {token} = useParams();
  const form = useForm<ResetPasswordPayload>({defaultValues: {token}});
  const resetPassword = useResetPassword(form);
  const {trans} = useTrans();
  const {branding} = useSettings();

  const heading = <Trans message="Reset your account password" />;

  const message = (
    <Trans
      values={{
        a: parts => (
          <Link className={LinkStyle} to="/register">
            {parts}
          </Link>
        ),
      }}
      message="Don't have an account? <a>Sign up.</a>"
    />
  );

  return (
    <>
      <main className="main-content">
        <div
          className="vh-100"
          style={{
            backgroundImage:
              'url(https://static.vecteezy.com/system/resources/previews/005/585/796/original/abstract-dark-purple-background-overlap-layer-on-dark-space-for-background-design-exclusive-wallpaper-design-for-poster-brochure-presentation-website-etc-vector.jpg)',
            backgroundSize: 'contain',
            backgroundRepeat: 'repeat',
            position: 'relative',
            minHeight: '500px',
          }}
        >
          <Container>
            <Row className="justify-content-center align-items-center height-self-center vh-100">
              <Col lg="5" md="12" className="align-self-center">
                <div className="user-login-card bg-body">
                  <div className="text-center">
                    <Link
                      to="/"
                      className="mb-40 block flex-shrink-0"
                      aria-label={trans({message: 'Go to homepage'})}
                    >
                      <img
                        src={branding.logo_light}
                        className="m-auto block h-42 w-auto"
                        alt=""
                      />
                    </Link>
                  </div>
                  <Form
                    form={form}
                    onSubmit={payload => {
                      resetPassword.mutate(payload);
                    }}
                  >
                    <FormTextField
                      className="mb-32"
                      name="email"
                      type="email"
                      label={<Trans message="Email" />}
                      required
                    />
                    <FormTextField
                      className="mb-32"
                      name="password"
                      type="password"
                      label={<Trans message="New password" />}
                      required
                    />
                    <FormTextField
                      className="mb-32"
                      name="password_confirmation"
                      type="password"
                      label={<Trans message="Confirm password" />}
                      required
                    />
                    <Button
                      className="block w-full"
                      type="submit"
                      variant="flat"
                      color="primary"
                      size="md"
                      disabled={resetPassword.isPending}
                    >
                      <Trans message="Reset password" />
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </main>
    </>
  );
}
